import HandshakeIcon from "@mui/icons-material/Handshake";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import StyleIcon from "@mui/icons-material/Style";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentsIcon from "@mui/icons-material/Payments";
import {
  GiBoxUnpacking,
  GiDuration,
  GiHumanTarget,
  GiOrganigram,
  GiPencilRuler,
  GiStoneBlock,
} from "react-icons/gi";
import {
  BsChatQuoteFill,
  BsEnvelopeAtFill,
  BsFileRuledFill,
  BsFillPatchQuestionFill,
  BsFillPinMapFill,
  BsPersonFillCheck,
  BsPersonFillSlash,
} from "react-icons/bs";
import { HiMiniHome, HiMiniMegaphone } from "react-icons/hi2";
import { AiFillBell, AiFillPropertySafety, AiFillSignal } from "react-icons/ai";
import {
  RiAccountPinCircleFill,
  RiContactsBookFill,
  RiContactsFill,
  RiFeedbackLine,
  RiFileList3Fill,
} from "react-icons/ri";
import {
  IoBag,
  IoStorefrontSharp,
  IoPodiumSharp,
  IoBookmarks,
} from "react-icons/io5";
import { TbSubtask } from "react-icons/tb";
import {
  MdAccountTree,
  MdCall,
  MdCallMissedOutgoing,
  MdCampaign,
  MdConfirmationNumber,
  MdDashboardCustomize,
  MdDiscount,
  MdOutlineApartment,
  MdOutlineCallMade,
  MdOutlineCrisisAlert,
  MdOutlineFreeCancellation,
} from "react-icons/md";
import {
  SiActivitypub,
  SiGoogleadsense,
  SiPlotly,
  SiProducthunt,
} from "react-icons/si";
import { BiLogoUnity, BiSolidDashboard } from "react-icons/bi";
import {
  FaCommentDots,
  FaHandsHelping,
  FaIdeal,
  FaPiedPiperPp,
  FaRegChartBar,
  FaShippingFast,
} from "react-icons/fa";
import { FaArrowsToCircle } from "react-icons/fa6";
import { PiClockCountdownFill, PiTabsFill } from "react-icons/pi";
import { TbTargetArrow } from "react-icons/tb";
import CustomFormList from "app/pages/Addmoremodule/CustomFormList";
import "./menus.css";
import { LuActivitySquare, LuGoal } from "react-icons/lu";
import { CgCommunity } from "react-icons/cg";

const token = localStorage.getItem("accesstoken");
let dynamicForm = token ? await CustomFormList(token).then((form) => form) : [];

const navConfig = [
  {
    id: null,
    title: "Home",
    path: `home`,
    type: "nav-item", // add type
    icon: (
      <HiMiniHome
        className="sideBarIcon"
        style={{ fontSize: 16, color: "#666565" }}
      />
    ),
    color: "#4B4B4B",
  },
  {
    id: 5,
    title: "Notification",
    path: `Home-Notifications`,
    // path: `sales-sales-Notifications`,
    type: "nav-item", // add type
    icon: (
      <AiFillBell
        className="sideBarIcon"
        style={{ fontSize: 16, color: "#666565" }}
      />
    ),
    color: "#4B4B4B",
  },

  {
    id: 12,
    title: "Marketing",
    path: "marketing",
    type: "collapsible", // add type
    icon: (
      <HiMiniMegaphone
        className="sideBarIcon"
        style={{ fontSize: 15, color: "#666565" }}
      />
    ),
    color: "#4B4B4B",
    children: [
      {
        id: null,
        title: "Contact Management",
        path: "contactmanagement",
        type: "collapsible", // add type
        icon: (
          <RiContactsBookFill
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
        children: [
          {
            id: 43,
            title: "Contacts",
            icon: (
              <RiContactsFill
                className="sideBarIcon"
                style={{ fontSize: 13, color: "#666565" }}
              />
            ),
            path: `app/contacts`,
            type: "nav-item", // add type
            color: "#4B4B4B",
          },
          {
            id: 44,
            title: "Calls",
            icon: (
              <MdCall
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `app/call-centre`,
            type: "nav-item", // add type
            color: "#4B4B4B",
          },
        ],
      },
      {
        id: null,
        title: "Lead Management",
        path: "leadmanagement",
        type: "collapsible", // add type
        icon: (
          <SiGoogleadsense
            className="sideBarIcon"
            style={{ fontSize: 13, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
        children: [
          {
            id: 45,
            title: "Dashboard",
            type: "nav-item", // add type
            icon: (
              <BiSolidDashboard
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `leads`,
            color: "#4B4B4B",
          },
          {
            id: 46,
            title: "Lead Generation",
            type: "nav-item", // add type
            icon: (
              <PiTabsFill
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `leads/leadgeneration`,
            color: "#4B4B4B",
          },
          {
            id: 47,
            title: "Lead Questions",
            path: `Lead-Questions`,
            type: "nav-item", // add type
            icon: (
              <BsFillPatchQuestionFill
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            color: "#4B4B4B",
          },
          {
            id: 48,
            title: "UnAsigned Leads",
            type: "nav-item", // add type
            icon: (
              <BsPersonFillSlash
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `leads/unAsignedLeads`,
            color: "#4B4B4B",
          },
          {
            id: 49,
            title: "Unqualified Leads",
            type: "nav-item", // add type
            icon: (
              <BsPersonFillSlash
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `leads/unqualifiedLeads`,
            color: "#4B4B4B",
          },
          {
            id: 50,
            title: "Qualified Leads",
            type: "nav-item", // add type
            icon: (
              <BsPersonFillCheck
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `leads/qualifiedLeads`,
            color: "#4B4B4B",
          },
        ],
      },

      {
        id: null,
        title: "Accounts",
        path: "accounts",
        type: "collapsible", // add type
        icon: <IoBag sx={{ fontSize: 14 }} />,
        color: "#4B4B4B",
        // children: [
        //  {
        //    title: "Accounts",
        //    icon: <MdManageAccounts className='sideBarIcon' fontSize={15} style={{ color: "#666565", }} />,
        //    // path: "/home",
        //    children: [
        //      // {
        //      //  title: "Dash board",
        //      //  icon: <BiSolidDashboard className='sideBarIcon' style={{ fontSize: 13, color: "#666565", }} />,
        //      //  path: `sales-sales-Dashboard`
        //      // },
        //      {
        //        title: "Accounts",
        //        icon: <RiAccountPinCircleFill className='sideBarIcon' style={{ fontSize: 14, color: "#666565", }} />,
        //        path: `sale-sales-Accounts`
        //      },
        //      {
        //        title: "Conversations",
        //        icon: <FaCommentDots className='sideBarIcon' style={{ fontSize: 13, color: "#666565", }} />,
        //        path: `sales-sales-Conversations`
        //      },
        //    ],
        //  },
        // ],
        children: [
          // {
          //  title: "Dash board",
          //  icon: <BiSolidDashboard className='sideBarIcon' style={{ fontSize: 13, color: "#666565", }} />,
          //  path: `sales-sales-Dashboard`
          // },
          // {
          //   id: 59,
          //   title: "Accounts",
          //   type: "nav-item", // add type
          //   icon: (
          //     <RiAccountPinCircleFill
          //       className="sideBarIcon"
          //       style={{ fontSize: 14, color: "#666565" }}
          //     />
          //   ),
          //   path: `sale-sales-Accounts`,
          //   color: "#4B4B4B",
          // },
          // {
          //   title: "Feedback",
          //   icon: (
          //     <MdOutlineFeed
          //       className="sideBarIcon"
          //       style={{ fontSize: 14, color: "#666565" }}
          //     />
          //   ),
          //   path: `sale-sales-feedback`,
          // color: "#4B4B4B",
          // },
          {
            id: 60,
            title: "Feedback Template",
            type: "nav-item", // add type
            icon: (
              <RiFeedbackLine
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `sale-sales-feedbacktemplate`,
            color: "#4B4B4B",
          },
          {
            id: null,
            path: `feedbackreports`,
            title: "Feedback Reports",
            type: "collapsible", // add type
            icon: <IoBag sx={{ fontSize: 14 }} />,
            color: "#4B4B4B",
            children: [
              // {
              //  title: "Dash board",
              //  icon: <BiSolidDashboard className='sideBarIcon' style={{ fontSize: 13, color: "#666565", }} />,
              //  path: `sales-sales-Dashboard`
              // },
              // {
              //   title: "Satisfaction Survey",
              //   icon: (
              //     <RiAccountPinCircleFill
              //       className="sideBarIcon"
              //       style={{ fontSize: 14, color: "#666565" }}
              //     />
              //   ),
              //   path: `SatisfactionSurveyReports`,
              // },
              {
                id: 61,
                title: "Feedback Summary",
                type: "nav-item", // add type
                icon: (
                  <RiAccountPinCircleFill
                    className="sideBarIcon"
                    style={{ fontSize: 14, color: "#666565" }}
                  />
                ),
                path: `feedbacksummaryReport`,
                color: "#4B4B4B",
              },
            ],
          },
          // {
          //   id: 62,
          //   title: "Conversations",
          //   type: "nav-item", // add type
          //   icon: (
          //     <FaCommentDots
          //       className="sideBarIcon"
          //       style={{ fontSize: 13, color: "#666565" }}
          //     />
          //   ),
          //   path: `sales-sales-Conversations`,
          //   color: "#4B4B4B",
          // },
        ],
      },

      {
        id: null,
        title: "Deal Management",
        path: "dealmanagement",
        type: "collapsible", // add type
        icon: <FaHandsHelping fontSize={14} color="#666565" />,
        color: "#4B4B4B",
        children: [
          {
            id: 51,
            title: "Dashboard",
            type: "nav-item", // add type
            icon: (
              <BiSolidDashboard
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `deals-dashboard`,
            color: "#4B4B4B",
          },
          {
            id: 52,
            title: "Deals",
            type: "nav-item", // add type
            icon: (
              <FaIdeal
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `deals`,
            color: "#4B4B4B",
          },
          {
            id: 53,
            title: "Pipeline deals",
            type: "nav-item", // add type
            icon: (
              <FaPiedPiperPp
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `deals-pipeline`,
            color: "#4B4B4B",
          },
        ],
      },
      {
        id: null,
        title: "Campaign",
        path: "campaign",
        type: "collapsible", // add type
        icon: (
          <MdCampaign className="sideBarIcon" fontSize={18} color="#666565" />
        ),
        color: "#4B4B4B",
        children: [
          {
            id: 54,
            title: "Dashboard",
            type: "nav-item", // add type
            icon: (
              <BiSolidDashboard
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `campaign-dashboard`,
            color: "#4B4B4B",
          },
          {
            id: 55,
            title: "Email",
            type: "nav-item", // add type
            icon: (
              <BsEnvelopeAtFill
                className="sideBarIcon"
                style={{ fontSize: 13, color: "#666565" }}
              />
            ),
            path: `campaign-email`,
            color: "#4B4B4B",
          },
          {
            id: 56,
            title: "Social Media Campaign",
            type: "nav-item", // add type
            icon: (
              <BsEnvelopeAtFill
                className="sideBarIcon"
                style={{ fontSize: 13, color: "#666565" }}
              />
            ),
            path: `SocialCampaign`,
            color: "#4B4B4B",
          },
          // { title: "Templates", path: "/campaign-email-template` },
          // {
          //   id: 57,
          //   title: "Statistics",
          //   type: "nav-item", // add type
          //   icon: (
          //     <TfiStatsUp
          //       className="sideBarIcon"
          //       style={{ fontSize: 14, color: "#666565" }}
          //     />
          //   ),
          //   path: `campaign-statistics`,
          // },
        ],
      },
    ],
  },

  {
    id: 13,
    title: "Sales",
    path: "sales",
    type: "collapsible", // add type
    icon: (
      <IoStorefrontSharp
        className="sideBarIcon"
        style={{ fontSize: 15, color: "#666565" }}
      />
    ),
    color: "#4B4B4B",
    children: [
      {
        id: 58,
        title: "Dash board",
        type: "nav-item", // add type
        icon: (
          <BiSolidDashboard
            className="sideBarIcon"
            style={{ fontSize: 13, color: "#666565" }}
          />
        ),
        path: `sales-sales-Dashboard`,
        color: "#4B4B4B",
      },

      // {
      //   id: null,
      //   title: "Configure Product",
      //   path: 'configureproduct',
      //   type: "collapsible", // add type
      //   icon: <InventoryIcon sx={{ fontSize: 14 }} />,
      //   color: "#4B4B4B",
      //   children: [
      //     {
      //       id: 63,
      //       title: "Product Category",
      //       type: "nav-item", // add type
      //       icon: (
      //         <SiProducthunt
      //           className="sideBarIcon"
      //           style={{ fontSize: 13, color: "#666565" }}
      //         />
      //       ),
      //       path: `sales-productCategory`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 64,
      //       title: "Product Group",
      //       type: "nav-item", // add type
      //       icon: (
      //         <SiProducthunt
      //           className="sideBarIcon"
      //           style={{ fontSize: 13, color: "#666565" }}
      //         />
      //       ),
      //       path: `sales-productGroup`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 65,
      //       title: "Product Item",
      //       type: "nav-item", // add type
      //       icon: (
      //         <SiProducthunt
      //           className="sideBarIcon"
      //           style={{ fontSize: 13, color: "#666565" }}
      //         />
      //       ),
      //       path: `sales-productItem`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 66,
      //       title: "Setting up discount",
      //       type: "nav-item", // add type
      //       icon: (
      //         <MdDiscount
      //           className="sideBarIcon"
      //           style={{ fontSize: 13, color: "#666565" }}
      //         />
      //       ),
      //       path: `sales-discount`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 67,
      //       title: "Supplier",
      //       type: "nav-item", // add type
      //       icon: (
      //         <GiBoxUnpacking
      //           className="sideBarIcon"
      //           style={{ fontSize: 13, color: "#666565" }}
      //         />
      //       ),
      //       path: `sale-supplier-list`,
      //       color: "#4B4B4B",
      //     },
      //   ],
      // },
      // {
      //   id: null,
      //   title: "Store",
      //   path: 'store',
      //   type: "collapsible", // add type
      //   icon: <InventoryIcon sx={{ fontSize: 14 }} />,
      //   color: "#4B4B4B",
      //   children: [
      //     {
      //       id: null,
      //       title: "Create Store",
      //       type: "nav-item", // add type
      //       icon: (
      //         <SiProducthunt
      //           className="sideBarIcon"
      //           style={{ fontSize: 13, color: "#666565" }}
      //         />
      //       ),
      //       path: `sales-createStore`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 68,
      //       title: "Product inward",
      //       type: "nav-item", // add type
      //       icon: (
      //         <FaArrowsToCircle
      //           className="sideBarIcon"
      //           style={{ fontSize: 13, color: "#656565" }}
      //         />
      //       ),
      //       path: `sales-product-inward`,
      //       color: "#4B4B4B",
      //     },
      //   ],
      // },
      // {
      //   id: 69,
      //   title: "Manage price",
      //   type: "nav-item", // add type
      //   path: `sales-Manage-price`,
      //   icon: <LocalOfferIcon sx={{ fontSize: 15 }} />,
      //   color: "#4B4B4B",
      // },
      // {
      //   id: 70,
      //   title: "Customer Enquiry",
      //   type: "nav-item", // add type
      //   path: `sales-customer-enquiry`,
      //   icon: <StyleIcon sx={{ fontSize: 15 }} />,
      //   color: "#4B4B4B",
      // },
      // {
      //   id: 71,
      //   title: "Quotation",
      //   type: "nav-item", // add type
      //   path: `sales-offer`,
      //   icon: <BsChatQuoteFill sx={{ fontSize: 14 }} />,
      //   color: "#4B4B4B",
      // },
      // {
      //   id: 72,
      //   title: "Expense",
      //   type: "nav-item", // add type
      //   path: `sales-expense`,
      //   icon: <BsChatQuoteFill sx={{ fontSize: 14 }} />,
      // },
      // {
      //   id: 73,
      //   title: "Order Management",
      //   type: "nav-item", // add type
      //   path: `sales-order-management`,
      //   icon: <RiFileList3Fill sx={{ fontSize: 15 }} />,
      //   color: "#4B4B4B",
      // },
      // {
      //   id: 74,
      //   title: "Schedule",
      //   type: "nav-item", // add type
      //   path: `sales-schedule`,
      //   icon: <PiClockCountdownFill sx={{ fontSize: 16 }} />,
      //   color: "#4B4B4B",
      // },
      // {
      //   id: 75,
      //   title: "Shipment",
      //   type: "nav-item", // add type
      //   path: `sales-shipment`,
      //   icon: <FaShippingFast sx={{ fontSize: 15 }} />,
      //   color: "#4B4B4B",
      // },
      // {
      //   id: 76,
      //   title: "Shipment calender",
      //   type: "nav-item", // add type
      //   path: `sales-shipmentcalender`,
      //   icon: <FaShippingFast sx={{ fontSize: 15 }} />,
      //   color: "#4B4B4B",
      // },
      // {
      //   id: null,
      //   title: "Delivery Confirmation",
      //   type: "nav-item", // add type
      //   path: `sales/ShipmentDelivery`,
      //   icon: <MdConfirmationNumber sx={{ fontSize: 15 }} />,
      //   color: "#4B4B4B",
      // },
      {
        id: 59,
        title: "Accounts",
        type: "nav-item", // add type
        icon: (
          <RiAccountPinCircleFill
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `sale-sales-Accounts`,
        color: "#4B4B4B",
      },

      {
        id: 58,
        title: "Booking",
        type: "nav-item", // add type
        icon: (
          <IoBookmarks
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `Booking`,
      },
      {
        id: 77,
        title: "Invoice",
        type: "nav-item", // add type
        path: `sales-invoice`,
        icon: <ReceiptIcon sx={{ fontSize: 15 }} />,
        color: "#4B4B4B",
      },
      // {
      //   id: 78,
      //   title: "Payments",
      //   type: "nav-item", // add type
      //   path: `sales-payments`,
      //   icon: <PaymentsIcon sx={{ fontSize: 15 }} />,
      //   color: "#4B4B4B",
      // },
      // {
      //   id: null,
      //   title: "Territory Management",
      //   path: 'territorymanagement',
      //   type: "collapsible", // add type
      //   icon: <HandshakeIcon sx={{ fontSize: 15 }} />,
      //   color: "#4B4B4B",
      //   children: [
      //     {
      //       id: null,
      //       title: "Territory Hierarchy",
      //       type: "nav-item", // add type
      //       icon: (
      //         <GiOrganigram
      //           className="sideBarIcon"
      //           style={{ fontSize: 13, color: "#666565" }}
      //         />
      //       ),
      //       path: `settings/territory-hierarchy`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 79,
      //       title: "Territory",
      //       type: "nav-item", // add type
      //       icon: (
      //         <BsFillPinMapFill
      //           className="sideBarIcon"
      //           style={{ fontSize: 13, color: "#666565" }}
      //         />
      //       ),
      //       path: `sales-territory-list`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 80,
      //       title: "Sales target",
      //       type: "nav-item", // add type
      //       icon: (
      //         <TbTargetArrow
      //           className="sideBarIcon"
      //           style={{ fontSize: 13, color: "#666565" }}
      //         />
      //       ),
      //       path: `sales-target`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 81,
      //       title: "Sales person target",
      //       type: "nav-item", // add type
      //       icon: (
      //         <GiHumanTarget
      //           className="sideBarIcon"
      //           style={{ fontSize: 13, color: "#666565" }}
      //         />
      //       ),
      //       path: `sales-person-target`,
      //       color: "#4B4B4B",
      //     },
      //   ],
      // },
    ],
  },

  // {
  //   id: 15,
  //   title: "Project Management",
  //   path: 'projectmanagement',
  //   type: "collapsible", // add type
  //   icon: (
  //     <MdAccountTree
  //       className="sideBarIcon"
  //       style={{ fontSize: 15, color: "#666565" }}
  //     />
  //   ),
  //   color: "#4B4B4B",
  //   children: [
  //     {
  //       id: 83,
  //       title: "Project",
  //       type: "nav-item", // add type
  //       path: `task/project`,
  //       color: "#4B4B4B",
  //     },

  //     {
  //       id: null,
  //       title: "Milestone",
  //       type: "nav-item", // add type
  //       path: `task/Milestone`,
  //       color: "#4B4B4B",
  //     },
  //     {
  //       id: null,
  //       title: "Deliverables",
  //       type: "nav-item", // add type
  //       path: `task/Deliverables`,
  //       color: "#4B4B4B",
  //     },
  //     {
  //       id: null,
  //       title: "Resource",
  //       type: "nav-item", // add type
  //       path: `task/Resource`,
  //       color: "#4B4B4B",
  //     },
  //     {
  //       id: 84,
  //       title: "Task",
  //       type: "nav-item", // add type
  //       path: `task/projectTask`,
  //       color: "#4B4B4B",
  //     },
  //     {
  //       id: null,
  //       title: "Resource Summary",
  //       type: "nav-item", // add type
  //       path: `task/Resourcesummary`,
  //       color: "#4B4B4B",
  //     },
  //     {
  //       id: null,
  //       title: "Project View",
  //       type: "nav-item", // add type
  //       path: `task/ProjectView`,
  //       color: "#4B4B4B",
  //     },
  //   ],
  // },

  {
    id: 16,
    title: "Task Management",
    path: "taskmanagement",
    type: "collapsible", // add type
    // path: "/task/list-task",
    icon: (
      <TbSubtask
        className="sideBarIcon"
        style={{ fontSize: 16, color: "#666565" }}
      />
    ),
    color: "#4B4B4B",
    children: [
      {
        id: 88,
        // title: "List Task",
        title: "Task List",
        type: "nav-item", // add type
        path: `task-management`,
        color: "#4B4B4B",
      },
      {
        id: 87,
        title: "Task Status",
        type: "nav-item", // add type
        path: `task-status`,
        color: "#4B4B4B",
      },
      {
        id: 89,
        title: "Task Gantt Chart",
        type: "nav-item", // add type
        path: `task-gantt-chart`,
        color: "#4B4B4B",
      },
      {
        id: 90,
        title: "Task Calendar",
        type: "nav-item", // add type
        path: `task-calendar`,
        color: "#4B4B4B",
      },
    ],
  },

  {
    id: 13,
    title: "Propery Management",
    type: "collapsible", // add type
    icon: (
      <IoStorefrontSharp
        className="sideBarIcon"
        style={{ fontSize: 15, color: "#666565" }}
      />
    ),
    children: [
      {
        id: 58,
        title: "Project",
        type: "nav-item", // add type
        icon: (
          <AiFillPropertySafety
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `PropertyLists`,
      },
      {
        id: 58,
        title: "Unit Master",
        type: "nav-item", // add type
        icon: (
          <BiLogoUnity
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `UnitMaster`,
      },
      {
        id: 58,
        title: "Block",
        type: "nav-item", // add type
        icon: (
          <GiStoneBlock
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `BlockLists`,
      },
      {
        id: 58,
        title: "Apartment",
        type: "nav-item", // add type
        icon: (
          <MdOutlineApartment
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `ApartmentLists`,
      },
      {
        id: 58,
        title: "Plot",
        type: "nav-item", // add type
        icon: (
          <SiPlotly
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `PlotLists`,
      },

      {
        id: 58,
        title: "Enquiry",
        type: "nav-item", // add type
        icon: (
          <SiPlotly
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `Enquiry`,
      },

      // {
      //   id: 58,
      //   title: "Booking",
      //   type: "nav-item", // add type
      //   icon: (
      //     <IoBookmarks
      //       className="sideBarIcon"
      //       style={{ fontSize: 14, color: "#666565" }}
      //     />
      //   ),
      //   path: `Booking`,
      // },
      {
        id: 58,
        title: "Booking Cancellation",
        type: "nav-item", // add type
        icon: (
          <MdOutlineFreeCancellation
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `BookingCancellation`,
      },
      {
        id: 58,
        title: "Property Portals",
        type: "nav-item", // add type
        icon: (
          <CgCommunity
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `PropertyPortals`,
      },
    ],
  },

  {
    id: 16,
    title: "Purchase",
    // path: "purchase",
    type: "collapsible", // add type
    icon: (
      <TbSubtask
        className="sideBarIcon"
        style={{ fontSize: 16, color: "#666565" }}
      />
    ),
    color: "#4B4B4B",
    children: [
      {
        id: 87,
        title: "Purchase Request",
        type: "nav-item", // add type
        path: `PurchaseRequest`,
        color: "#4B4B4B",
      },
      {
        id: 88,
        title: "Pending PRs",
        type: "nav-item", // add type
        path: `PendingPRs`,
        color: "#4B4B4B",
      },
      {
        id: 89,
        title: "RFQ",
        type: "nav-item", // add type
        path: `RFQ`,
        color: "#4B4B4B",
      },
      {
        id: 90,
        title: "Supplier Quotation",
        type: "nav-item", // add type
        path: `SupplierQuotation`,
        color: "#4B4B4B",
      },
      {
        id: 90,
        title: "Purchase Catalogue",
        type: "nav-item", // add type
        path: `PurchaseCatalogue`,
        color: "#4B4B4B",
      },
      {
        id: 90,
        title: "Supplier",
        type: "nav-item", // add type
        path: `Supplier`,
        color: "#4B4B4B",
      },
      {
        id: 90,
        title: "Price Comparision",
        type: "nav-item", // add type
        path: `PriceComparision`,
        color: "#4B4B4B",
      },
      {
        id: 90,
        title: "Purchase Order",
        type: "nav-item", // add type
        path: `PurchaseOrder`,
        color: "#4B4B4B",
      },
      {
        id: 90,
        title: "PO Amendment",
        type: "nav-item", // add type
        path: `PoAmendment`,
        color: "#4B4B4B",
      },
      {
        id: 90,
        title: "PO Cancellation",
        type: "nav-item", // add type
        path: `PoCancellation`,
        color: "#4B4B4B",
      },
    ],
  },

  {
    id: 16,
    title: "Inventory",
    path: "inventory",
    type: "collapsible", // add type
    // path: "/task/list-task",
    icon: (
      <TbSubtask
        className="sideBarIcon"
        style={{ fontSize: 16, color: "#666565" }}
      />
    ),
    color: "#4B4B4B",
    children: [
      {
        id: 88,
        // title: "List Task",
        title: "Material Receipt",
        type: "nav-item", // add type
        path: `MaterialReceipt`,
        color: "#4B4B4B",
      },
      {
        id: 87,
        title: "Material Request",
        type: "nav-item", // add type
        path: `MaterialRequest`,
        color: "#4B4B4B",
      },
      {
        id: 89,
        title: "Task Gantt Chart",
        type: "nav-item", // add type
        path: `task-gantt-chart`,
        color: "#4B4B4B",
      },
      {
        id: 90,
        title: "Task Calendar",
        type: "nav-item", // add type
        path: `task-calendar`,
        color: "#4B4B4B",
      },
    ],
  },



  // {
  //   id: 17,
  //   title: "Work Flow",
  //   path: "workflow",
  //   type: "collapsible", // add type
  //   // path: "/task/list-task",
  //   icon: (
  //     <GiPencilRuler
  //       className="sideBarIcon"
  //       style={{ fontSize: 14, color: "#666565" }}
  //     />
  //   ),
  //   color: "#4B4B4B",
  //   children: [
  //     {
  //       id: 91,
  //       title: "Work Flow Rule",
  //       type: "nav-item", // add type
  //       icon: (
  //         <BsFileRuledFill
  //           className="sideBarIcon"
  //           style={{ fontSize: 14, color: "#666565" }}
  //         />
  //       ),
  //       path: `Work-Flow`,
  //       color: "#4B4B4B",
  //     },
  //   ],
  // },

  // {
  //   title: "Lead Questions",
  //   // path: "/task/list-task",
  //   icon: <PiQuestionFill className='sideBarIcon' fontSize={15} style={{ color: "#666565" }} />,
  //   children: [
  //     {
  //       title: "Lead Questions",
  //       icon: <RiFileTextFill className='sideBarIcon' style={{ fontSize: 13, color: "#666565", }} />,
  //       path: "/Lead-Questions",
  //     },
  //     {
  //       title: "Lead Qualify Questions",
  //       icon: <BsFillClipboard2CheckFill className='sideBarIcon' style={{ fontSize: 13, color: "#666565", }} />,
  //       path: "/Lead-QualifyQuestions",
  //     }
  //   ]
  // },

  {
    id: 19,
    type: "collapsible",
    title: "Reports",
    path: "reports",
    icon: (
      <AiFillSignal
        className="sideBarIcon"
        style={{ fontSize: 14, color: "#666565" }}
      />
    ),
    color: "#4B4B4B",
    children: [
      // {
      //   id: null,
      //   type: "collapsible",
      //   title: "Sales Performance",
      //   path: "salesperformance",
      //   color: "#4B4B4B",
      //   children: [
      //     {
      //       id: 110,
      //       type: "nav-item",
      //       title: "Sales pipeline report",
      //       path: `reports/SalesPipeLineReport`,
      //       color: "#4B4B4B",
      //     },
      //     // { id: 112,title: "Sales forecast report", path: `reports/SalesForecastReport` },
      //     // {
      //     // id: 113,
      //     //   title: "Sales rep performance report",
      //     //   path: `reports/SalesRepPerformanceReport`,
      //     // },
      //     {
      //       id: 114,
      //       type: "nav-item",
      //       title: "Pro/Ser performance report",
      //       path: `reports/ProAndSerPerformanceReport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 115,
      //       type: "nav-item",
      //       title: "Sales activity report",
      //       path: `reports/SalesActivityReports`,
      //       color: "#4B4B4B",
      //     },
      //   ],
      // },

      //new comment karthik

      // {
      //   id: null,
      //   type: "collapsible",
      //   title: "Lead and Opportunity Management",
      //   path: "leadandopportunitymanagement",
      //   color: "#4B4B4B",
      //   children: [
      //     {
      //       id: 125,
      //       type: "nav-item",
      //       title: "Lead Conversion Report",
      //       path: `leads/addleads/LeadConversionreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 126,
      //       type: "nav-item",
      //       title: "Opportunity Pipeline Report",
      //       path: `leads/addleads/Opportunitypipelinereport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 127,
      //       type: "nav-item",
      //       title: "Sales forecast by stage report",
      //       path: `leads/addleads/Salesforecast`,
      //       color: "#4B4B4B",
      //     },
      //     // {
      //     // id: null,
      //     //   title: "Deal size and win rate report",
      //     //   path:  `leads/addleads/DealSize`,
      //     // },
      //     {
      //       id: 128,
      //       type: "nav-item",
      //       title: "Sales Funner Report",
      //       path: `leads/addleads/SalesFunnelReport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 129,
      //       type: "nav-item",
      //       title: "Lead Source Report",
      //       path: `leads/addleads/LeadSourceReports`,
      //       color: "#4B4B4B",
      //     },
      //     // {
      //     // id: null,
      //     //   title: "Sales Pipeline report",
      //     //   path:  `leads/addleads/SalesPipelineReport`,
      //     // },
      //     {
      //       id: 130,
      //       type: "nav-item",
      //       title: "Opportunity Aging Report",
      //       path: `leads/addleads/OpportunityAgingReport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 131,
      //       type: "nav-item",
      //       title: "Conversion Rate Report",
      //       path: `leads/addleads/ConversionRateReport`,
      //       color: "#4B4B4B",
      //     },
      //     // {
      //     // id: null,
      //     //   title: "Sales forecast report",
      //     //   path:  `leads/addleads`,
      //     // },
      //   ],
      // },
      // {
      //   id: null,
      //   type: "collapsible",
      //   title: "Customer Engagement",
      //   path: "customerengagementreports",
      //   color: "#4B4B4B",
      //   children: [
      //     // {
      //     // id: null,
      //     // type: "nav-item",
      //     //   title: "Customer interaction history report ",
      //     //   path: `customer-support/CustomerInteractionHistoryReport`,
      //     // },
      //     {
      //       id: 132,
      //       type: "nav-item",
      //       title: "Email Campaign performance report",
      //       path: `customer-support/EmailCampaignPerformanceReport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 133,
      //       type: "nav-item",
      //       title: "Social media engagement report",
      //       path: `customer-support/SocialMediaEngagementReport`,
      //       color: "#4B4B4B",
      //     },
      //     // {
      //     // id: null,
      //     //   title: "Customer satisfaction report",
      //     //   path: `customer-support/CustomerSatisfactionReport`,
      //     // },
      //     // {
      //     // id: null,
      //     //   title: "Website traffic report",
      //     //   path: `customer-support/WebsiteTrafficReport`,
      //     // },
      //   ],
      // },
      // {
      //   id: null,
      //   type: "collapsible",
      //   title: "Marketing Effectiveness",
      //   path: "marketingeffectiveness",
      //   color: "#4B4B4B",
      //   children: [
      //     {
      //       id: 134,
      //       type: "nav-item",
      //       title: "Lead generation report",
      //       path: `leads/LeadGenerationReport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 135,
      //       type: "nav-item",
      //       title: "Conversion rate report",
      //       path: `leads/ConversionMarketingRateReport`,
      //       color: "#4B4B4B",
      //     },
      //     // { id: null,title: "Revenue attribution  report", path:  `leads` },
      //     // {
      //     // id: null,
      //     //   title: "Customer lifetime value report",
      //     //   path:  `leads`,
      //     // },
      //     // { id: null,title: "Marketing ROI report", path:  `leads` },
      //   ],
      // },
      // {
      //   id: null,
      //   type: "collapsible",
      //   title: "Customer Retention",
      //   path: "customerretention",
      //   color: "#4B4B4B",
      //   children: [
      //     {
      //       id: 136,
      //       type: "nav-item",
      //       title: "Customer Churn Report",
      //       path: `customer-support/CustomerChurnReport`,
      //       color: "#4B4B4B",
      //     },
      //     // {
      //     // id: null,
      //     //   title: "Customer loyalty report",
      //     //   path: `customer-support/CustomerLoyaltyReport`,
      //     // },
      //     // {
      //     // id: null,
      //     //   title: "Customer Satisfaction report",
      //     //   path: `customer-support/CustomerRetentionSatisfactionReport`,
      //     // },
      //     // {
      //     // id: null,
      //     //   title: "Customer Engagement report",
      //     //   path: `customer-support/CustomerEngagementReport`,
      //     // },
      //     // {
      //     // id: null,
      //     //   title: "Customer lifetime value report",
      //     //   path: `customer-support/CustomerLifetimeValueReport`,
      //     // },
      //   ],
      // },

      // {
      // id: null,
      //   title: "Financial Metrics",
      //   children: [
      //     { id: null,title: "Revenue report ", path: `home` },
      //     { id: null,title: "Sales Performance report", path: `home` },
      //     { id: null,title: "Profit & Loss report", path: `home` },
      //     { id: null,title: "Cost analysis report", path: `home` },
      //     { id: null,title: "Catch flow report", path: `home` },
      //   ],
      // },

      //karthik add
      // {
      //   id: null,
      //   type: "collapsible",
      //   title: "General Reports",
      //   path: "generalreports",
      //   color: "#4B4B4B",
      //   children: [
      //     {
      //       id: 137,
      //       type: "nav-item",
      //       title: "Daily sales report",
      //       path: `reports/Dailysalesreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 138,
      //       type: "nav-item",
      //       title: "Weekly sales report",
      //       path: `reports/Weeklysalesreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 139,
      //       type: "nav-item",
      //       title: "Monthly sales report",
      //       path: `reports/Monthlysalesreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 136,
      //       type: "nav-item",
      //       title: "Customer Churn report",
      //       path: `reports/CustomerChurnreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 140,
      //       type: "nav-item",
      //       title: "Customer addition report",
      //       path: `reports/Customeradditionreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 141,
      //       type: "nav-item",
      //       title: "Customer retention report",
      //       path: `reports/Customerretentionreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 142,
      //       type: "nav-item",
      //       title: "Sales rep perform report",
      //       path: `reports/newreports/Salesrepperformreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 143,
      //       type: "nav-item",
      //       title: "Lead and Opportunity report",
      //       path: `reports/LeadandOpportunityreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 144,
      //       type: "nav-item",
      //       title: "Sales performance report",
      //       path: `reports/Salesperformancereport`,
      //       color: "#4B4B4B",
      //     },
      //   ],
      // },

      // {
      //   id: null,
      //   type: "collapsible",
      //   title: "Sales Funnel Reports",
      //   path: "salesfunnel",
      //   color: "#4B4B4B",
      //   children: [
      //     {
      //       id: 145,
      //       type: "nav-item",
      //       title: "Sales Funnel Report",
      //       path: `reports/salesFunnelreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 146,
      //       type: "nav-item", // add type
      //       title: "Pipeline Development Report",
      //       path: `reports/PipelineDevelopmentreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: 147,
      //       type: "nav-item", // add type
      //       title: "Stage Distribution CRM Report",
      //       path: `reports/StageDistributionCRMreport`,
      //       color: "#4B4B4B",
      //     },
      //   ],
      // },
      {
        id: null,
        type: "collapsible",
        title: "Property Lead Reports",
        path: "LeadReport",
        color: "#4B4B4B",
        children: [
          {
            id: null,
            type: "nav-item",
            title: "Lead Source Report",
            path: `reports/LeadSourceReport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item", // add type
            title: "Lead Conversion Report",
            path: `reports/LeadConversionreport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item", // add type
            title: "Lead Response Time Report",
            path: `reports/LeadResponseTimereport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item", // add type
            title: "Lead Activity Report",
            path: `reports/LeadActivityreport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item", // add type
            title: "Lead Qualification Report",
            path: `reports/LeadQualificationreport`,
            color: "#4B4B4B",
          },
        ],
      },
      {
        id: null,
        type: "collapsible",
        title: "Deal/Transaction Reports",
        path: "Deal/TransactionReport",
        color: "#4B4B4B",
        children: [
          {
            id: null,
            type: "nav-item",
            title: "Deal Pipeline Report",
            path: `reports/DealPipelinereport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item",
            title: "Deal Closing Report",
            path: `reports/DealClosingreport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item",
            title: "Lost Deals Report",
            path: `reports/LostDealsreport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item",
            title: "Deal Value Report",
            path: `reports/DealValuereport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item",
            title: "Time-to-Close Report",
            path: `reports/TimetoClosereport`,
            color: "#4B4B4B",
          },
        ],
      },
      {
        id: null,
        type: "collapsible",
        title: "Property Reports",
        path: "PropertyReport",
        color: "#4B4B4B",
        children: [
          {
            id: null,
            type: "nav-item",
            title: "Property Status Report",
            path: `reports/PropertyStatusreport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item",
            title: "Property Inquiry Report",
            path: `reports/PropertyInquiryreport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item",
            title: "Listing Activity Report",
            path: `reports/ListingActivityreport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item",
            title: "Top Performing Properties Report",
            path: `reports/TopPerformingPropertiesreport`,
            color: "#4B4B4B",
          },
          // {
          //   id: null,
          //   type: "nav-item",
          //   title: "Inventory Report",
          //   path: `reports/Inventoryreport`,
          //   color: "#4B4B4B",
          // },
        ],
      },
      // {
      //   id: null,
      //   type: "collapsible",
      //   title: "Agent/Team Performance Reports",
      //   path: "AgentTeamPerformanceReport",
      //   color: "#4B4B4B",
      //   children: [
      //     {
      //       id: null,
      //       type: "nav-item",
      //       title: "Agent Sales Report",
      //       path: `reports/AgentSalesreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: null,
      //       type: "nav-item",
      //       title: "Lead Response Report by Agent",
      //       path: `reports/LeadResponseReportAgentreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: null,
      //       type: "nav-item",
      //       title: "Agent Conversion Rate Report",
      //       path: `reports/AgentConversionRatereport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: null,
      //       type: "nav-item",
      //       title: "Commission Report",
      //       path: `reports/Commissionreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: null,
      //       type: "nav-item",
      //       title: "Agent Activity Report",
      //       path: `reports/AgentActivityreport`,
      //       color: "#4B4B4B",
      //     },
      //   ],
      // },
      {
        id: null,
        type: "collapsible",
        title: "Client Engagement Reports",
        path: "ClientEngagementReport",
        color: "#4B4B4B",
        children: [
          {
            id: null,
            type: "nav-item",
            title: "Client Satisfaction Report",
            path: `reports/ClientSatisfactionreport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item",
            title: "Client Communication Report",
            path: `reports/ClientCommunicationreport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item",
            title: "Client Retention Report",
            path: `reports/ClientRetentionreport`,
            color: "#4B4B4B",
          },
        ],
      },
      {
        id: null,
        type: "collapsible",
        title: "Marketing Reports",
        path: "MarketingReport",
        color: "#4B4B4B",
        children: [
          // {
          //   id: null,
          //   type: "nav-item",
          //   title: "Marketing Campaign Performance Report",
          //   path: `reports/MarketingCampaignPerformancereport`,
          //   color: "#4B4B4B",
          // },
          {
            id: null,
            type: "nav-item",
            title: "Lead Source Performance Report",
            path: `reports/LeadSourcePerformancereport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item",
            title: "Email Campaign Report",
            path: `reports/EmailCampaignreport`,
            color: "#4B4B4B",
          },
          {
            id: null,
            type: "nav-item",
            title: "Social Media Engagement Report",
            path: `reports/SocialMediaEngagementreport`,
            color: "#4B4B4B",
          },
        ],
      },
      // {
      //   id: null,
      //   type: "collapsible",
      //   title: "Financial Reports",
      //   path: "FinancialReport",
      //   color: "#4B4B4B",
      //   children: [
      //     {
      //       id: null,
      //       type: "nav-item",
      //       title: "Revenue Report",
      //       path: `reports/Revenuereport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: null,
      //       type: "nav-item",
      //       title: "Expense vs Revenue Report",
      //       path: `reports/ExpenseRevenuereport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: null,
      //       type: "nav-item",
      //       title: "Commission Report",
      //       path: `reports/FinanceCommissionreport`,
      //       color: "#4B4B4B",
      //     },
      //     {
      //       id: null,
      //       type: "nav-item",
      //       title: "Profitability Report",
      //       path: `reports/Profitabilityreport`,
      //       color: "#4B4B4B",
      //     },
      //   ],
      // },
    ],
  },

  {
    id: 18,
    title: "Analytics",
    path: "analytics",
    type: "collapsible", // add type
    icon: (
      <AiFillSignal
        className="sideBarIcon"
        style={{ fontSize: 14, color: "#666565" }}
      />
    ),
    color: "#4B4B4B",
    children: [
      // new rout start
      {
        id: 92,
        title: "Sales by Customer",
        type: "nav-item", // add type
        path: `analytics/salesbycustomer`,
        icon: (
          <MdOutlineCrisisAlert
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      {
        id: 93,
        title: "Activity Overview",
        type: "nav-item", // add type
        path: `analytics/activityoverview`,
        icon: (
          <LuActivitySquare
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      {
        id: 94,
        title: "Sales Goals",
        type: "nav-item", // add type
        path: `analytics/SalesGoals`,
        icon: (
          <LuGoal
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      // {
      //   title: "Stage Conversion",
      //   path:  `analytics/stageconversionbyowner`,
      // },
      // {
      //   title: "Stage Duration Analysis",
      //   path:  `analytics/stagedurationanalysis`,
      // },
      // {
      //   title: "Time to First Action",
      //   path:  `analytics/TimetoFirstActionbyOwner`,
      // },
      {
        id: 95,
        title: "Lead Sources",
        type: "nav-item", // add type
        path: `analytics/LeadSources`,
        icon: (
          <SiGoogleadsense
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      {
        id: 96,
        title: "Call Duration",
        type: "nav-item", // add type
        path: `analytics/CallDuration`,
        icon: (
          <GiDuration
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      {
        id: 97,
        title: "Call Length Vs Time Of Day",
        type: "nav-item", // add type
        path: `analytics/CallLengthVsTimeOfDay`,
        icon: (
          <MdOutlineCallMade
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      // {
      // id: null,
      //   title: "CRM DashBoard",
      // type: 'nav-item', // add type
      //   path:  `analytics/CRMDashBoard`,
      // },
      {
        id: 98,
        title: "Sales KPI Dashboard",
        type: "nav-item", // add type
        path: `analytics/SalesKPIDashboard`,
        icon: (
          <BiSolidDashboard
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      // {
      //   id: 99,
      //   title: "Churn Analysis Dashboard",
      //   type: "nav-item", // add type
      //   path: `analytics/ChurnAnalysisDashboard`,
      // },
      {
        id: 100,
        title: "Sales Dashboard",
        type: "nav-item", // add type
        path: `analytics/SalesDashboard`,
        icon: (
          <MdDashboardCustomize
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      // {
      //   title: "Key Performance Indicators",
      // type: 'nav-item', // add type
      //   path:  `analytics/KeyPerformanceIndicator`,
      // },
      {
        id: 101,
        title: "Sales Opportunities Overview",
        type: "nav-item", // add type
        path: `analytics/SalesOpportunitiesOverview`,
        icon: (
          <IoPodiumSharp
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      {
        id: 102,
        title: "Opportunities Individual Report",
        type: "nav-item", // add type
        path: `analytics/OpportunitiesIndividualReport`,
        icon: (
          <MdOutlineCrisisAlert
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      {
        id: 103,
        title: "Activity Individual Report",
        type: "nav-item", // add type
        path: `analytics/ActivityIndividualReport`,
        icon: (
          <SiActivitypub
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      // {
      //   title: "Customer Rentention Dashboard",
      // type: 'nav-item', // add type
      //   path:  `analytics/CustomerRententionDashboard`,
      // },
      // {
      //   title: "Lead Performance",
      // type: 'nav-item', // add type
      //   path:  `analytics/LeadPerformance`,
      // },
      {
        id: 104,
        title: "Call Activities",
        type: "nav-item", // add type
        path: `analytics/CallActivities`,
        icon: (
          <MdCallMissedOutgoing
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      // {
      //   title: "Monthly Sales Board",
      // type: 'nav-item', // add type
      //   path:  `analytics/MonthlySalesDashboard`,
      // },
      // {
      //   title: "Sales Product Performance Dashboard",
      // type: 'nav-item', // add type
      //   path:  `analytics/SalesProductPerformance`,
      // },
      // {
      //   title: "Executive Sales Performance Dashboard",
      // type: 'nav-item', // add type
      //   path:  `analytics/ExecutiveSalesPerformance`,
      // },
      // {
      //   title: "Total Sales And Growth",
      // type: 'nav-item', // add type
      //   path:  `analytics/TotalSalesAndGrowth`,
      // },
      // {
      //   id: 105,
      //   title: "Sales Volume By Channel",
      //   type: "nav-item", // add type
      //   path: `analytics/SalesVolumeByChannel`,
      // },
      // {
      //   id: 106,
      //   title: "Monthly Sales Activity Report",
      //   type: "nav-item", // add type
      //   path: `analytics/MonthlySalesActivityReport`,
      // },
      // {
      //   id: 107,
      //   title: "Monthly Sales Call Report Template",
      //   type: "nav-item", // add type
      //   path: `analytics/MonthlySalesCallReport`,
      // },
      // {
      //   id: 108,
      //   title: "Simple Monthly Sales Report",
      //   type: "nav-item", // add type
      //   path: `analytics/SimpleMonthlySalesReport`,
      // },
      {
        id: 109,
        title: "Monthly Retail Sales",
        type: "nav-item", // add type
        path: `analytics/MonthlyRetailSales`,
        icon: (
          <FaRegChartBar
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        color: "#4B4B4B",
      },
      // new rout end
      // {
      //   title: "Form 1",
      //   path: "/analytics",
      // },
      // {
      //   title: "Form 2",
      //   path: "/analytics",
      // },
      // {
      //   title: "Form 3",
      //   path: "/analytics",
      // },
      // {
      //   title: "Form 4",
      //   path: "/analytics",
      // },
      // {
      //   title: "Form 5",
      //   path: "/analytics",
      // },
      // {
      //   title: "Form 6",
      //   path: "/analytics",
      // },
      // {
      //   title: "Form 7",
      //   path: "/analytics",
      // },
    ],
  },

  // {
  //   id: 20,
  //   title: "Custom Form",
  //   path: 'custom-form',
  //   type: "collapsible", // add type
  //   icon: (
  //     <HiMiniMegaphone
  //       className="sideBarIcon"
  //       style={{ fontSize: 15, }}
  //     />
  //   ),
  //   color: "#4B4B4B",
  //   children: [...dynamicForm],
  // },

  {
    id: 21,
    title: "Customer Engagement",
    path: "customerengagementlist",
    type: "nav-item", // add type
    icon: (
      <HiMiniMegaphone
        className="sideBarIcon"
        style={{ fontSize: 15, color: "#666565" }}
      />
    ),
    color: "#4B4B4B",
  },

  // {
  //   title: "Add New Module ",
  //   // path: "/task/list-task",
  //   icon: <BsClipboard2CheckFill className='sideBarIcon' style={{ fontSize: 15 }} />,
  //   children: [
  //     {
  //       title: "Add Form",
  // type: 'nav-item', // add type
  //       path: "/Add-Module",
  //     },
  //     {
  //       title: "Add Form 1",
  // type: 'nav-item', // add type
  //       path: "/Add-Formbuilder",
  //     }
  //   ]
  // }
];

export default navConfig;
