import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { FaEdit, FaHouseUser, FaIdeal } from "react-icons/fa";
import { MdLeaderboard, MdBatchPrediction, } from "react-icons/md";


const settingmenus = [
  {
    id: 11,
    title: "Settings",
    path: "settings",
    type: "collapsible",
    icon: <SettingsIcon sx={{ fontSize: 20 }} />,
    color: "#4B4B4B",
    children: [

      {
        id: 26,
        path: `settings/edit-company-details`,
        title: "Edit Company Details",
        type: "nav-item",
        icon: <FaEdit style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 27,
        path: `settings/roles&users`,
        title: "Roles & Users",
        type: "nav-item",
        icon: <FaHouseUser style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 29,
        path: `settings/lead-scoring`,
        title: "Lead Scoring",
        type: "nav-item",
        icon: <MdLeaderboard style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 30,
        path: `settings/lead-values`,
        title: "Lead Scoring Values",
        type: "nav-item",
        icon: <MdLeaderboard style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 31,
        path: `settings/lead-prediction`,
        title: "Lead Prediction",
        type: "nav-item",
        icon: <MdBatchPrediction style={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },
      {
        id: 32,
        path: `settings/lead-Scoringsetup`,
        title: "Lead Status",
        type: "nav-item",
        icon: <MdBatchPrediction style={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },
      {
        id: 33,
        path: `settings/deal-WeightSetup`,
        title: "Deal Weight Setup",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 34,
        path: `settings/deal-FactorSetup`,
        title: "Deal Factor Setup",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 35,
        path: `settings/deal-PredictSetup`,
        title: "Deal Predict Setup",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      // {
      //   id: 36,
      //   path: `settings/deal-probability`,
      //   title: "Deal Probability",
      //   type: "nav-item",
      //   icon: <FaIdeal style={{ fontSize: 16, }} />,
      // color: "#4B4B4B",
      // },
      {
        id: 37,
        path: `settings/Setup`,
        title: "Auto Responder Setup",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 38,
        path: `template`,
        title: "Templates",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: null,
        path: `settings/TaxSetup`,
        title: "Tax Setup",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: null,
        path: `settings/FactorySetup`,
        title: "Factory Setup",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 39,
        path: `InvoiceTemplate`,
        title: "Invoice Template",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 40,
        path: `SalesCallActivity`,
        title: "Sales Person Target",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 41,
        path: `AddAgent`,
        title: "Add Agent",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 41,
        path: `CrmConfiguration`,
        title: "CRM Configuration",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 41,
        path: `settings/LeadScoring`,
        title: "Dynamic Lead Scoring",
        type: "nav-item",
        icon: <MdBatchPrediction style={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },
    ],
  },
];
export default settingmenus;
